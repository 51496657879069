import React from "react";
import theme from "theme";
import { Theme, Input } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"testing"} />
		<Helmet>
			<title>
				Client Portal | Wavedropper
			</title>
			<meta name={"description"} content={"Pattern of Success,\nMapped for your Business"} />
			<meta property={"og:title"} content={"Client Portal | Wavedropper"} />
			<meta property={"og:description"} content={"Pattern of Success,\nMapped for your Business"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final-green.png?v=2024-04-24T16:57:01.241Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
		</Helmet>
		<Components.BusinessProfile>
			<Override slot="SectionContent" />
			<Override slot="button" />
			<Override slot="button1" />
			<Override slot="box14" />
			<Override slot="text18" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
			<Override slot="text7" />
			<Override slot="text10" />
			<Override slot="text11" />
			<Override slot="text12" />
			<Override slot="text13" />
			<Override slot="text14" />
			<Override slot="text15" />
			<Override slot="text9" />
			<Override slot="text" />
			<Override slot="box3" />
			<Override slot="text1" />
			<Override slot="text8" />
			<Override slot="text19" />
			<Override slot="text20" />
			<Override slot="text21" />
			<Override slot="text22" />
			<Override slot="text23" />
			<Override slot="text24" />
			<Override slot="box1" />
			<Override slot="link1" />
			<Override slot="link3" />
			<Override slot="box2" />
			<Override slot="box4" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="box12" />
			<Override slot="box6" />
			<Override slot="box9" />
			<Override slot="billing-address-input" required as="textarea" type="text" />
			{" "}
		</Components.BusinessProfile>
		<Components.UserProfile>
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="box1" />
			<Override slot="box3" />
			<Override slot="box6" />
		</Components.UserProfile>
		<Components.Projects>
			<Override slot="box18" />
			<Override slot="box12" />
			<Override slot="box15" />
			<Override slot="box9" />
			<Override slot="box27" />
			<Override slot="box30" />
			<Override slot="box33" />
			<Override slot="box36" />
		</Components.Projects>
		<Components.CustomerService />
		<Components.PlanfixComponent apiUrl="https://wavedropper.planfix.com/rest" apiToken="c7a4974f056fe8b38543021cd4b90a1e" />
		<Components.ExampleUsageOfCrmUtils />
		<Input display="block" placeholder-color="LightGray" background="white" type="tel" />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65f854bd8e2e8e002179c15d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});